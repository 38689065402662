<template>
  <div class="info-container">
    <header>
      <el-page-header @back="goBack" content="公安派出所日常消防监督检查记录"></el-page-header>
    </header>
    <main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="检查记录" name="first">
          <table class="unit-attr">
            <tr>
              <td colspan="2">单位（场所）名称</td>
              <td colspan="6">
                {{info.unitName}}
              </td>
              <td colspan="2">企业法人</td>
              <td colspan="2">
                {{info.legalName}}
              </td>
            </tr>
            <tr>
              <td colspan="2">地址</td>
              <td colspan="6">
                {{info.address}}
              </td>
              <td colspan="2">单位性质</td>
              <td colspan="2">
                {{info.unitNatureName}}
              </td>
            </tr>
            <tr>
              <td colspan="2">所用建筑面积(㎡)</td>
              <td colspan="2">
                <el-input v-model="detail.buildingArea" type="number" placeholder="请输入" size="normal" clearable></el-input>
              </td>
              <td colspan="2">所用建筑具体层数</td>
              <td colspan="2">
                <el-input v-model="detail.num" type="number" placeholder="请输入" size="normal" clearable></el-input>
              </td>
              <td colspan="2">所用建筑高度(m)</td>
              <td colspan="2">
                <el-input v-model="detail.tall" type="number" placeholder="请输入" size="normal" clearable></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2">检查承办人</td>
              <td colspan="1">
                <el-button type="text" size="default" @click="open('mainHead')">电子签名板</el-button>
              </td>
              <td colspan="1" class="nopadding">
                <el-button type="primary" size="default" @click="preview(detail.mainHead)" style="width:100%;height:100%;border-radius:0;">预览</el-button>
              </td>
              <td colspan="2">检查协办人</td>
              <td colspan="1">
                <el-button type="text" size="default" @click="open('head')">电子签名板</el-button>
              </td>
              <td colspan="1" class="nopadding">
                <el-button type="primary" size="default" @click="preview(detail.head)" style="width:100%;height:100%;border-radius:0;">预览</el-button>
              </td>
              <td colspan="2">检查日期</td>
              <td colspan="2">
                <el-date-picker v-model="detail.checkTime" type="date" size="normal" placeholder="选择日期时间">
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="17">1<br />单<br />位<br />履<br />行<br />消<br />防<br />安<br />全<br />职<br />责<br />情<br />况</td>
              <td colspan="1" rowspan="3">1.1合法性</td>
              <td colspan="2">1.1.1被查建筑物名称</td>
              <td colspan="4">
                <el-input v-model="detail.buildingName" placeholder="请输入" size="normal"></el-input>
              </td>
              <td colspan="2">1.1.2公众聚集场所</td>
              <td colspan="2">
                <el-radio-group v-model="detail.publicGather">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="7">1.1.3依法通过投入使用、营业前消防安全检查</td>
              <td colspan="3">
                <el-radio-group v-model="detail.safeCheck">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <el-radio-group v-model="detail.legitimacy">
                  <el-radio v-for="(item,i) in tr1" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="3">1.2消防安全管理</td>
              <td colspan="2">1.2.1消防安全制度</td>
              <td colspan="3">
                <el-radio-group v-model="detail.safeRule">
                  <el-radio v-for="(item,i) in [{id:0,label:'有'},{id:1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.2.2员工消防安全教育培训</td>
              <td colspan="3">
                <el-radio-group v-model="detail.safeTrain">
                  <el-radio v-for="(item,i) in [{id:0,label:'组织开展'},{id:1,label:'组织未开展'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">1.2.3防火检查</td>
              <td colspan="3">
                <el-radio-group v-model="detail.fireCheck">
                  <el-radio v-for="(item,i) in [{id:0,label:'组织开展'},{id:1,label:'组织未开展'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.2.4灭火和应急疏散预案</td>
              <td colspan="3">
                <el-radio-group v-model="detail.cutFire">
                  <el-radio v-for="(item,i) in [{id:0,label:'有'},{id:1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">1.2.5消防演练</td>
              <td colspan="3">
                <el-radio-group v-model="detail.firePractice">
                  <el-radio v-for="(item,i) in [{id:0,label:'有'},{id:1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.2.6其他情况</td>
              <td colspan="3">
                <el-input v-model="detail.other2" placeholder="请输入" size="normal"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="4">1.3建筑防火</td>
              <td colspan="2">1.3.1消防车通道</td>
              <td colspan="3">
                <el-radio-group v-model="detail.carExit">
                  <el-radio v-for="(item,i) in [{id:0,label:'畅通'},{id:1,label:'被堵塞、占用'},{id:2,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.3.2疏散通道</td>
              <td colspan="3">
                <el-radio-group v-model="detail.runExit">
                  <el-radio v-for="(item,i) in [{id:0,label:'畅通'},{id:1,label:'被堵塞、占用'},{id:2,label:'闭锁'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">1.3.3安全出口</td>
              <td colspan="3">
                <el-radio-group v-model="detail.safeExit">
                  <el-radio v-for="(item,i) in [{id:0,label:'畅通'},{id:1,label:'被堵塞、占用'},{id:2,label:'闭锁'},{id:3,label:'缺少'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.3.4防火门</td>
              <td colspan="3">
                <el-radio-group v-model="detail.fireDoor">
                  <el-radio v-for="(item,i) in [{id:0,label:'完好有效'},{id:1,label:'常闭式防火门常开'},{id:2,label:'损坏'},{id:3,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">1.3.5疏散指示标志</td>
              <td colspan="3">
                <el-radio-group v-model="detail.runFlag">
                  <el-radio v-for="(item,i) in [{id:0,label:'完好有效'},{id:1,label:'损坏'},{id:2,label:'缺少'},{id:3,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.3.6应急照明</td>
              <td colspan="3">
                <el-radio-group v-model="detail.light">
                  <el-radio v-for="(item,i) in [{id:0,label:'完好有效'},{id:1,label:'损坏'},{id:2,label:'缺少'},{id:3,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">1.3.6人员密集场所外墙门窗上是否设置影响逃生、灭火救援的障碍物</td>
              <td colspan="3">
                <el-radio-group v-model="detail.block">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'},{id:2,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">1.3.7其他情况</td>
              <td colspan="3">
                <el-input v-model="detail.other3" placeholder="请输入" size="normal"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="4">1.4消防设施</td>
              <td colspan="4">1.4.1室内消火栓</td>
              <td colspan="6">
                <el-radio-group v-model="detail.insideHydrant">
                  <el-radio v-for="(item,i) in [{id:0,label:'未设置'},{id:1,label:'完好有效'},{id:2,label:'损坏'},{id:3,label:'无水'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">1.4.2灭火器</td>
              <td colspan="6">
                <el-radio-group v-model="detail.fireExtinguisher">
                  <el-radio v-for="(item,i) in [{id:0,label:'未设置'},{id:1,label:'完好有效'},{id:2,label:'失效'},{id:3,label:'缺少'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">1.4.3建筑消防设施</td>
              <td colspan="6">
                <el-radio-group v-model="detail.buildingFacility">
                  <el-radio v-for="(item,i) in [{id:0,label:'定期检测记录'},{id:1,label:'无记录'},{id:2,label:'未定期检测'},{id:3,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">1.4.4物业服务企业对管理区域内共用消防设施是否维护管理</td>
              <td colspan="6">
                <el-radio-group v-model="detail.protectManage">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'},{id:2,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="3">1.5危险品管理</td>
              <td colspan="6">1.5.1是否存在违反规定使用明火作业或在具有火灾、爆炸危险的场所吸烟、使用明火</td>
              <td colspan="4">
                <el-radio-group v-model="detail.useFire">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'},{id:2,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="6">1.5.2是否存在违反消防安全规定进入生产、储存易燃易爆危险品场所</td>
              <td colspan="4">
                <el-radio-group v-model="detail.restoreDanger">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'},{id:2,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="6">1.5.3生产、储存、经营易燃易爆危险品的场所是否与居住场所设置在同一建筑物内</td>
              <td colspan="4">
                <el-radio-group v-model="detail.sameBuilding">
                  <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'},{id:2,label:'不涉及'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="3">2.村(居)民委员会履行消防安全责任情况</td>
              <td colspan="2">2.1消防安全管理人</td>
              <td colspan="2">
                <el-radio-group v-model="detail.fireManagePerson">
                  <el-radio v-for="(item,i) in [{id:0,label:'确定'},{id:1,label:'不确定'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">2.2消防安全工作制度</td>
              <td colspan="1">
                <el-radio-group v-model="detail.fireSafeRule">
                  <el-radio v-for="(item,i) in [{id:0,label:'有'},{id:1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">2.3防火安全公约</td>
              <td colspan="1">
                <el-radio-group v-model="detail.fireSafePromise">
                  <el-radio v-for="(item,i) in [{id:0,label:'有'},{id:1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">2.4消防宣传教育</td>
              <td colspan="3">
                <el-radio-group v-model="detail.publicizeEducate">
                  <el-radio v-for="(item,i) in [{id:0,label:'开展'},{id:1,label:'未开展'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">2.5防火安全检查</td>
              <td colspan="3">
                <el-radio-group v-model="detail.fireSafeCheck">
                  <el-radio v-for="(item,i) in [{id:0,label:'开展'},{id:1,label:'未开展'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">2.6消防水源、消防车通道、消防器材</td>
              <td colspan="3">
                <el-radio-group v-model="detail.waterExitFacility">
                  <el-radio v-for="(item,i) in [{id:0,label:'开展'},{id:1,label:'未开展'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
              <td colspan="2">2.7多种形式消防组织</td>
              <td colspan="3">
                <el-radio-group v-model="detail.manyWays">
                  <el-radio v-for="(item,i) in [{id:0,label:'维护管理'},{id:1,label:'未维护管理'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="4">移送公安消防机构处理的内容</td>
              <td colspan="2">发现的下列第</td>
              <td colspan="2" class="nopadding">
                <el-input-number v-model="detail.findBehavior" size="normal" :min="1" :max="3" :step="1" maxLea>
                </el-input-number>
              </td>
              <td colspan="2">项消防安全违法行为，移送</td>
              <td colspan="3">
                <el-input v-model="detail.other2" placeholder="请输入" size="normal"></el-input>
              </td>
              <td colspan="1">依法处理</td>
            </tr>
            <tr>
              <td colspan="10" style="text-align:left">1.建筑物未依法通过公安机关消防机构消防验收，擅自投入使用</td>
            </tr>
            <tr>
              <td colspan="10" style="text-align:left">2.建筑物未依法进行竣工验收消防备案</td>
            </tr>
            <tr>
              <td colspan="10" style="text-align:left">3.公众聚集场所未依法通过使用、营业前消防安全检查，擅自投入使用、营业</td>
            </tr>
            <tr>
              <td colspan="2" rowspan="1">备注</td>
              <td colspan="10">
                <el-input type="textarea" :rows="4" v-model="detail.remark" placeholder="请输入" :maxlength="-1" :show-word-limit="false">
                </el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="1">检查结果</td>
              <td colspan="10">
                <el-radio-group v-model="detail.checkResult">
                  <el-radio v-for="(item,i) in [{id:0,label:'合格'},{id:1,label:'不合格'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="1">责令整改通知</td>
              <td colspan="10">
                <el-radio-group v-model="detail.rectifyStatus" @change="changeStatus">
                  <el-radio v-for="(item,i) in [{id:0,label:'通知'},{id:1,label:'不通知'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
          </table>
          <div class="btn">
            <el-button type="primary" size="default" @click="submitTask" v-if="detail.rectifyStatus!==0||(detail.rectifyStatus===0&&detail.rectifyId>0)">立即提交</el-button>
            <el-button size="default" @click="goBack">返 回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="责令整改通知书" name="second">
          <table class="unit-attr2">
            <tr>
              <td colspan="12">根据《中华人民共和国消防法》第五十三条规定，我所于_对你单位（场所）进行消防监督检查，发现你单位（场所）存 在下列第_项消防安全违法行为，现责令改正：</td>
            </tr>
            <tr>
              <td colspan="12">
                1.<el-radio-group v-model="correct.option1">
                  <el-radio v-for="(item,i) in [{id:0,label:'未制定消防安全制度'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                2.<el-radio-group v-model="correct.option2">
                  <el-radio v-for="(item,i) in [{id:0,label:'未组织'},{id:1,label:'防火检查'},{id:2,label:'消防安全教育培训'},{id:3,label:'消防演练'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                3.<el-radio-group v-model="correct.option3">
                  <el-radio v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭疏散通道、安全出口'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                4.<el-radio-group v-model="correct.option4">
                  <el-radio v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭消防车通道，妨碍消防车通行'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                5.<el-radio-group v-model="correct.option5">
                  <el-radio v-for="(item,i) in [{id:0,label:'埋压'},{id:1,label:'圈占'},{id:2,label:'遮挡消防栓'},{id:3,label:'占用防火间距'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                6.<el-radio-group v-model="correct.option6">
                  <el-radio v-for="(item,i) in [{id:0,label:'室内消防栓'},{id:1,label:'灭火器'},{id:2,label:'疏散指示标志'},{id:3,label:'应急照明未保持完好有效'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                7.<el-radio-group v-model="correct.option7">
                  <el-radio v-for="(item,i) in [{id:0,label:'人员密集场所外墙门窗上设置影响逃生、灭火救援的障碍物'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                8.<el-radio-group v-model="correct.option8">
                  <el-radio v-for="(item,i) in [{id:0,label:'违反消防安全规定进入'},{id:1,label:'生产'},{id:2,label:'储存易燃易爆危险品场所'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                9.<el-radio-group v-model="correct.option9">
                  <el-radio v-for="(item,i) in [{id:0,label:'违反规定使用明火作业'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                10.<el-radio-group v-model="correct.option10">
                  <el-radio v-for="(item,i) in [{id:0,label:'在具有火灾、爆炸危险的场所'},{id:1,label:'吸烟'},{id:2,label:'使用明火'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                11.<el-radio-group v-model="correct.option11">
                  <el-radio v-for="(item,i) in [{id:0,label:'生产'},{id:1,label:'储存'},{id:2,label:'经营易燃易爆危险品的场所与居住场所设置在同一建筑物内的；'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                12.<el-radio-group v-model="correct.option12">
                  <el-radio v-for="(item,i) in [{id:0,label:'未对建筑消防设施定期进行维修保养'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                13.<el-radio-group v-model="correct.option13">
                  <el-radio v-for="(item,i) in [{id:0,label:'其他消防安全违法行为和火灾隐患'},{id:-1,label:'无'}]" :key="i" :label="item.id">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr v-show="correct.option13===0">
              <td colspan="4" class="center">备注</td>
              <td colspan="8">
                <el-input type="textarea" :rows="2" v-model="correct.remark" placeholder="请输入">
                </el-input>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="center">被检查单位(场所)签收:</td>
              <td colspan="4" class="center">
                <el-button type="text" size="default" @click="open('headName')" style="width:100%;height:100%;">电子签名板</el-button>
              </td>
              <td colspan="4" class="center nopadding">
                <el-button type="primary" size="default" @click="preview(correct.headName)" style="width:100%;height:100%;border-radius:0;">预览</el-button>
              </td>
            </tr>
            <tr>
              <td colspan="12">一式两份，一份交被检查单位(场所)，一份存档。</td>
            </tr>
          </table>
          <div class="btn">
            <el-button type="primary" size="default" @click="submitCorrect">立即提交</el-button>
            <el-button size="default" @click="goBack">返 回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="隐患照片" name="third">
          <div class="upload">
            <div class="upload-box">
              <div class="upload-title">照片一</div>
              <el-upload ref="upload" :headers="{Authorization:token}" action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getDangerImage1" :on-error="error" :on-remove="removeUrl1" list-type="picture-card" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div class="upload-box">
              <div class="upload-title">照片二</div>
              <el-upload ref="upload" :headers="{Authorization:token}" action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getDangerImage2" :on-error="error" :on-remove="removeUrl2" list-type="picture-card" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </main>
    <!-- 签名板 -->
    <el-dialog title="签名板" :visible.sync="signShow" width="40%" @close="clear">
      <sign-canvas class="sign-canvas" ref="SignCanvas" :options="options" v-model="sign" />
      <span>
        <el-button @click="signShow = false">取消</el-button>
        <el-button type="danger" @click="clear">清空</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog :visible.sync="imgShow">
      <img width="100%" :src="previewImg" alt="">
    </el-dialog>
  </div>
</template>

<script>
import SignCanvas from 'sign-canvas'
export default {
  components: {
    SignCanvas
  },
  data () {
    return {
      info: {},
      detail: {},
      correct: {},
      signShow: false,
      imgShow: false,
      flag: '',
      sign: '',
      previewImg: '',
      unitId: 0,
      activeName: 'first',
      uploadFlag1: false,
      uploadFlag2: false,
      options: {
        isDpr: false, // 是否使用dpr兼容高倍屏 [Boolean] 可选
        lastWriteSpeed: 1, // 书写速度 [Number] 可选
        lastWriteWidth: 2, // 下笔的宽度 [Number] 可选
        lineCap: 'round', // 线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]正方形线帽
        lineJoin: 'bevel', // 线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 550, // canvas宽高 [Number] 可选
        canvasHeight: 370, // 高度  [Number] 可选
        isShowBorder: true, // 是否显示边框 [可选]
        bgColor: '#fff', // 背景色 [String] 可选
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: '#409EFF', // 网格颜色  [String] 可选
        writeWidth: 5, // 基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: '#101010', // 轨迹颜色  [String] 可选
        isSign: true, // 签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: 'png' // 下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
      tr1: [
        {
          id: 0,
          label: '1998年9月1日之前竣工建筑且此后未改建(含装修、用途变更)'
        },
        {
          id: 1,
          label: '依法通过消防验收'
        },
        {
          id: 2,
          label: '依法进行竣工验收消防备案'
        }
      ]
    }
  },
  created () {
    this.unitId = parseInt(this.$route.params.id)
    this.getUnit()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    open (flag) {
      this.flag = flag
      this.signShow = true
    },
    // 单位信息
    async getUnit () {
      const { data: result } = await this.$axios.get('/unit/listWeb', { params: { unitId: this.unitId } })
      if (result.code === 200) {
        this.info = result.data[0]
      }
    },
    // 保存签名
    async save () {
      const base64 = this.$refs.SignCanvas.saveAsImg()
      const arr = base64.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const timestamp = new Date().getTime()
      const file = new File([u8arr], `sign${timestamp}.png`, { type: mime })
      const params = new FormData()
      params.append('file', file)
      const { data: result } = await this.$axios.post('/app-version/uploadFile', params, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (result.code === 200) {
        this.$message.success('上传成功')
        this.signShow = false
        switch (this.flag) {
          case 'mainHead':
            this.detail.mainHead = result.data
            break
          case 'head':
            this.detail.head = result.data
            break
          case 'headName':
            this.correct.headName = result.data
            break
        }
      } else {
        this.$message.error(result.msg)
      }
    },
    async submitTask () {
      const confirm = await this.$confirm('是否已完成填写，点击确定提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        this.detail.unitId = this.unitId
        this.detail.findBehavior = this.detail.findBehavior - 1
        const { data: result } = await this.$axios.post('/supervisionInspectDetail/add', this.detail)
        if (result.code === 200) {
          this.$message.success('提交成功')
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    async submitCorrect () {
      this.correct.unitId = this.unitId
      const { data: result } = await this.$axios.post('/rectifyNotice/add', this.correct)
      if (result.code === 200) {
        this.detail.rectifyId = result.data.id
        this.submitTask()
      } else {
        this.$message.error(result.msg)
      }
    },
    getDangerImage1 (res, file, fileList) {
      this.correct.aimgUrl = res.data
      this.uploadFlag1 = true
    },
    getDangerImage2 (res, file, fileList) {
      this.correct.bimgUrl = res.data
      this.uploadFlag2 = true
    },
    removeUrl1 (file) {
      this.uploadFlag1 = false
    },
    removeUrl2 (file) {
      this.uploadFlag2 = false
    },
    error (err, file, fileList) {
      this.$message.error(err)
    },
    preview (url) {
      if (!url) {
        this.$message.error('暂无签名')
        return
      }
      console.log(url)
      this.previewImg = url
      this.imgShow = true
    },
    handlePictureCardPreview (file) {
      this.preview(file.url)
    },
    changeStatus (val) {
      if (val === 0) {
        this.activeName = 'second'
      }
    },
    clear () {
      this.$refs.SignCanvas.canvasClear()
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 100%;
  height: 100%;
  background: rgba(22, 57, 98, 0.6);
  box-sizing: border-box;
  overflow-x: hidden;
  header {
    background-color: rgb(7, 63, 112);
    height: 60px;
    box-sizing: border-box;
    padding: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    ::v-deep .el-page-header__content {
      color: #fff;
    }
  }
  main {
    height: calc(100% - 60px);
    ::v-deep .el-tabs__nav {
      width: 100%;
      .el-tabs__item {
        width: 33%;
        color: #fff;
      }
      .is-active {
        color: #409eff;
      }
      .el-tabs__active-bar {
        width: 33% !important;
      }
    }
    .unit-attr,
    .unit-attr2 {
      width: 100%;
      border: 1px solid #ddd;
      box-sizing: border-box;
      border-collapse: collapse;
      table-layout: fixed;
      word-break: break-all;
      white-space: normal;
      td {
        height: 45px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        padding: 5px;
        ::v-deep .el-textarea__inner {
          background-color: transparent;
          color: #fff;
          border: none;
        }
        ::v-deep .el-input__inner {
          background-color: transparent;
          color: #fff;
          border: none;
        }
        .el-radio {
          color: #fff;
          ::v-deep .el-radio__label {
            padding-left: 5px;
          }
        }
        .el-input-number {
          width: 100%;
          height: 100%;
          ::v-deep .el-input-number__decrease,
          ::v-deep .el-input-number__increase {
            background-color: rgba(7, 104, 159, 0.15);
            color: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          ::v-deep .el-input {
            height: 100%;
            .el-input__inner {
              height: 100%;
            }
          }
        }
      }
      .nopadding {
        padding: 0;
      }
      .center {
        text-align: center;
      }
    }
    .unit-attr2 {
      text-align: left;
    }
    .btn {
      padding: 10px;
      box-sizing: border-box;
    }
    .upload {
      display: flex;
      justify-content: space-around;
      .upload-box {
        div {
          margin-bottom: 10px;
        }
      }
      ::v-deep .hide .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
